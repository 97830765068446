<template>

  <section class="aboutUs-component">
    
    <div class="aboutus-start">
      <div class="content-about">
        <h1>About Us</h1>
        <h2>We are Sudamericana Rugs</h2>
        <p>With a passion for craftsmanship and quality, we have devoted ourselves to bringing the finest rugs to homes worldwide. 
          Our journey commenced with a commitment to authenticity and excellence, and today, we persist in upholding these values in every aspect of our business,
          from our skilled artisans to our meticulous attention to detail.</p>
        
        
      </div>
      <div class="img-about">
        <img src="../../assets/img-content/2.jpg" alt="work">
      </div>
    </div>



    <div class="box-about2">
      <div class="about2">
        <div class="img-about2">
        <img src="../../assets/img-content/7.jpg" alt="our-work">  
        </div>
      <div class="content-about2">
        <h1>Our Work</h1>
          <h2>We work with sheepskins rugs</h2>
          <p>We create customized projects tailored to your needs. 
            From standard rugs to exclusive designs, we are committed to bringing your vision to life. 
            We adapt to any size, shape, and design to ensure that each project is unique and exceptional. 
            Discover how we can transform your space with our exquisite sheepskin rugs.</p>
  
      </div>
  
      </div>


    </div>


    
  </section>

</template>

<script>

  export default  {
    name: 'about-us',
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
}


</script>

<style scoped lang="css">
.aboutUs-component{
  background-color: #fff;
}

 
.aboutus-start{
  width: 80%;
  height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  margin: auto;
  padding: 0 35px;
  flex-wrap: wrap;
}

.content-about{
 flex: 1 1 0%;
 text-align: left;
}
.content-about h1{
  font-size: 1.35rem;
    line-height: 2.2rem;
    letter-spacing: 3.5px;
    text-transform: uppercase;
    font-weight: 450;
    color: #5b627d;
}
.content-about h2{
  font-size: 4.8rem;
    line-height: 5.2rem;
    font-weight: 500;
    color: #232938;
    margin-top: 5px;
    margin-bottom: 15px;
}
.content-about p{
  display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: left;
    line-height: 1.7;
    font-size: 1.3rem;
    margin: 0px 0px 2rem;
    color: rgb(0, 0, 0);
    letter-spacing: 0.1px;
}
.img-about{
  flex: 1 1 0%;
}

.img-about img{
  width: 100%;
}

.box-about2{
  background-color: #F1EFEF;
  width: 100%;
  height: 750px;
}
.about2{
  width: 80%;
  height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  margin: auto;
  padding: 0 35px;
  flex-wrap: wrap-reverse;
}

.content-about2{
 flex: 1 1 0%;
 text-align: left;
}
.content-about2 h1{
  font-size: 1.35rem;
    line-height: 2.2rem;
    letter-spacing: 3.5px;
    text-transform: uppercase;
    font-weight: 450;
    color: #5b627d;
}
.content-about2 h2{
  font-size: 4.8rem;
    line-height: 5.2rem;
    font-weight: 500;
    color: #232938;
    margin-top: 5px;
    margin-bottom: 15px;
}
.content-about2 p{
  display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: left;
    line-height: 1.7;
    font-size: 1.3rem;
    margin: 0px 0px 2rem;
    color: rgb(0, 0, 0);
    letter-spacing: 0.1px;
}
.img-about2{
  flex: 1 1 0%;
  
}

.img-about2 img{
  width: 100%;
}


@media screen and (max-width: 800px){
  .aboutus-start, .box-about2{
    height: auto;
    margin-top: 20px;
    width: 100%;
    flex-direction: column;
  }
  .about2{
    height: auto;
    
    padding: 0;
    flex-direction: column-reverse;
  }
  .content-about, .content-about2{
    text-align: center;
    padding: 0;
    width: 100%;
    word-wrap: break-word;
  }
  .content-about h1, .content-about2 h1{
    font-size: 1.2rem;
    line-height: 3.6rem;
    font-weight: 500;
  }
  .content-about h2, .content-about2 h2{
    font-size: 3.1rem;
    line-height: 3.9rem;
    font-weight: 500;
    color: #232938;
  }
  .content-about p, .content-about2 p{
      text-align: center;
  }
  .img-about , .img-about2{
    width: auto;
  }
}


</style>
