import Vue from "vue";
import VueRouter from "vue-router";

import Index from './components/Index.vue'

import AboutUs from './components/Elements/AboutUs.vue'
import ContactUs from './components/Elements/ContactUs.vue'
import RugGallery from './components/Elements/RugGallery.vue'

Vue.use(VueRouter)

export const router = new VueRouter({
    mode: 'hash',
    routes: [
        { path: '/', redirect: '/index'},
        { path: '/index', component: Index},

        { path: '/aboutUs', component: AboutUs}, 
        { path: '/contactUs', component: ContactUs}, 
        { path: '/rugGallery', component: RugGallery},
               
    ],
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 };
      },
})