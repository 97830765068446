<template>
  <section class="footer_component">
    
    <div class="footer">
      
      <div class="link-footer">
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/sudamericanarugs/"
                  target="_blank"
                  ><i class="fa-brands fa-instagram"></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61556635740288"
                  target="_blank"
                  ><i class="fa-brands fa-facebook"></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.pinterest.com/sudamericanarugs/"
                  target="_blank"
                  ><i class="fa-brands fa-pinterest"></i
                ></a>
              </li>
            </ul>
          </div>
      <div class="footer_container">
        <h4 class="footer_title">2024 © SUDAMERICANA RUGS</h4>
      </div>


    </div>
  </section>
</template>

<script>
export default {
  name: "footerComponent",
  props: [],
  mounted() {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style scoped lang="css">
* {
  margin: 0;
  box-sizing: border-box;
}
.footer{
  display: flex;
  height: 100%;
  justify-content: space-between;
}
.footer_component {
  background-color: #46433c;
  height: 65px;
  width: 100%;
}
.footer_container {
  height: 100%;
  color: white;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: nowrap;
  justify-content: center;
}
.footer_title {

  margin-right: 15px;
}
.footer_text {
 
  margin-right: 15px;
}

.link-footer{
  display: flex;
  align-items: center;
}
.link-footer ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.link-footer li {
  list-style: none;
  padding-right: 30px;
}

.link-footer li a {
  color: #FFF;
  font-size: 18px;
}
</style>
