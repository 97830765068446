import emailjs from 'emailjs-com';

emailjs.init("ItPEqfYWqWhNqX8ro");

const sendEmail = (formData) => {

    console.log("Contenido del objeto formData:", JSON.stringify(formData));

    const serviceID = "service_u783qmo";
    const templateID = "template_9fet6kt";

  const emailParams = {
    name: formData.name,
    email: formData.email,
    message: formData.message
  };

  return emailjs.send(serviceID, templateID, emailParams);
};

export default sendEmail;