<template>
  <section class="contactUs-component">
    <div class="title">
      <h1>Contact Us</h1>
    </div>
    <div class="container_form">
      <div class="contact-info">
        <p>
          These are our social networks and our contact information.<br /><br />
          You can send an email here to place an order
        </p>
        <a><i class="fa-solid fa-envelope"></i>info@sudamericanarugs.com</a
        >
        <div class="aside-footer">
          <ul>
            <li>
              <a
                href="https://www.instagram.com/sudamericanarugs/"
                target="_blank"
                ><i class="fa-brands fa-instagram"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=61556635740288"
                target="_blank"
                ><i class="fa-brands fa-facebook"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.pinterest.com/sudamericanarugs/"
                target="_blank"
                ><i class="fa-brands fa-pinterest"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>

      <!-- -------------------------- Formulario ---------------------------- -->
      <div class="formulario">
       <div class="vform">
         <vue-form :state="formState" @submit.prevent="sendEmail()" id="form">
           <!-- ---------------------------------- -->
           <validate tag="div">
             <input
               type="text"
               id="name"
               placeholder="Name"
               class="field"
               autocomplete="off"
               v-model.trim="formData.name"
               name="name"
               required
             />
             <field-messages name="name" show="$dirty">
               <div slot="required">Required field</div>
             </field-messages>
           </validate>
           
           <!-- ---------------------------------- -->
           <validate tag="div">
             <input
               type="email"
               id="email"
               placeholder="Email"
               class="field"
               autocomplete="off"
               v-model.trim="formData.email"
               name="email"
               required
             />
             <!-- mensajes de validación -->
             <field-messages name="email" show="$dirty">
               <div slot="required">Required field</div>
             </field-messages>
           </validate>
           
           <!-- ---------------------------------- -->
           <validate tag="div">
             <textarea
               type="textarea"
               cols="30"
               rows="10"
               id="message"
               placeholder="Your Message"
               class="field"
               autocomplete="off"
               v-model.trim="formData.message"
               name="message"
               required
             ></textarea>
             <field-messages name="message" show="$dirty">
               <div slot="required">Required field</div>
             </field-messages>
           </validate>
 
           <input
             type="submit"
             name="enviar"
             id="enviar"
             class="btn-enviar"
             value="Send"
             :disabled="formState.$invalid"
           />
         </vue-form>

       </div>

        <!-- <hr>
      <p><u>formData</u></p>
      <pre>{{ formData }}</pre>

      <hr>
      <p><u>formState</u></p>
      <pre>{{ formState }}</pre>  
 -->
      </div>
    </div>
  </section>
</template>

<script>
import sendEmail from "../../services/emailService";

export default {
  name: "contact-us",
  props: [],
  data() {
    return {
      formState: {},
      formData: this.getInitialData(),
    };
  },
  methods: {
    sendEmail() {
      sendEmail(this.formData)
        .then(() => {
          alert("Email sent successfully!");
        })
        .catch((err) => {
          alert("Error sending email: " + err);
        });
    },
    getInitialData() {
      return {
        email: null,
        name: null,
        message: null,
      };
    },
  },
};
</script>

<style scoped lang="css">
.contactUs-component {
  margin: 0;
  padding: 0;
  background-color: #f1efef;
}
.title h1 {
  font-size: 1.35rem;
  line-height: 4.2rem;
  letter-spacing: 3.5px;
  text-transform: uppercase;
  font-weight: 500;
  color: #5b627d;
  margin-top: 60px;
}

.container_form {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
}

.container_form p {
  line-height: 1.6;
  margin-bottom: 30px;
}
.container_form a {
  font-size: 17px;
  display: block;
  text-decoration: none;
  width: 100%;
  margin-bottom: 15px;
  color: #232938;

  font-weight: 700;
}
.container_form a i {
  color: #232938;
  margin-right: 10px;
}
.contact-info {
  margin: 30px;
}
.aside-footer ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.aside-footer li {
  list-style: none;
  padding-right: 30px;
}

.aside-footer li a {
  font-size: 22px;
}

/*_____________________________Form _________________________________*/
.formulario {
  display: flex;
  margin: 30px;
  width: auto;
}
.formulario .vform {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.container_form .vform .field,
textarea {
  width: 100%;
  /* padding: 15px, 10px; */
  font-size: 16px;
  border: 1px solid #dbdbdb;
  margin-bottom: 20px;
  border-radius: 3px;
  outline: 0px;
}
.container_form .vform textarea {
  max-width: 530px;
  min-width: 530px;
  min-height: 140px;
  max-height: 150px;
}

.container_form .btn-enviar {
  text-decoration: none;
  text-transform: uppercase;
  background-color: #a7a38c;
  padding: 10px;
  border: 0;
  border-radius: 2px;
  letter-spacing: 1px;
  color: white;
  cursor: pointer;
  width: 100%;
}

.container_form .btn-enviar:hover {
  background: #928d6f;
  transition: all 0.4s ease-in-out;
}

@media screen and (max-width: 550px) {
  .contact-info {
    margin: 0;
  }
  .title h1 {
    margin: 0;
    padding: 0;
    margin-top: 10px;
  }

  .container_form {
    margin: 0;
    padding: 0;
  }
  .formulario {
    display: flex;
    width: 100%;
    margin: 0;
  }
  .formulario br {
    display: none;
  }
  .vform form{
    width: 95%;
  }
  .container_form .vform .field, .container_form .btn-enviar {
    width: 95%;
  }
  .formulario .vform {
    align-items: center;
    margin-bottom: 10px;
  }
  .container_form .vform textarea {
    max-width: 95%;
    min-width: 95%;
    min-height: 150px;
    max-height: 150px;
  }
  
}
</style>
