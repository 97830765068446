<template>
  <section class="rugGallery-container">

    <div class="gallery-title">
      <h1>Rug Gallery</h1>
    </div>


    <!-------------------------------- card-container -------------------------------->
    <div class="card-container">
      <!-------------------------------- card -------------------------------->
      <div class="card">
        <img
          src="../../assets/img/img_1.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Sheepskin Sheared</h2>
          <h3>Embers</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_2.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Sheepskin Sheared</h2>
          <h3>Coffee</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_3.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Sheepskin Sheared</h2>
          <h3>Wheat</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_4.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Sheepskin Sheared</h2>
          <h3>Cobalt</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_5.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Cusco</h2>
          <h3>Embers</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_6.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Cusco</h2>
          <h3>Steel</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_7.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Andes</h2>
          <h3>Embers</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_8.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Andes</h2>
          <h3>Arctic White</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_9.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Chalten</h2>
          <h3>Embers</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_10.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Calafate</h2>
          <h3>Embers</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_11.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Caracas</h2>
          <h3>Arctic White</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_12.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Sheepskin Mufflon</h2>
          <h3>Embers</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_13.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Goatskin Curly</h2>
          <h3>Arctic White</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_14.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Goatskin Straight</h2>
          <h3>Coal</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_15.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Goatskin Straight</h2>
          <h3>Steel</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_16.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Goatskin Curly</h2>
          <h3>Gold</h3>
        </div>
      </div>

      <div class="card">
        <img
          src="../../assets/img/img_17.jpg"
          alt="card-img"
          class="card-img"
        />
        <div class="content">
          <h2>Goatskin Curly</h2>
          <h3>Misty Black</h3>
        </div>
      </div>

    </div>

  </section>
</template>

<script>
export default {
  name: "rug-gallery",
  props: [],
  mounted() {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style scoped lang="css">
.gallery-title{
  width: 100%;
}
.gallery-title h1{
  font-size: 1.35rem;
    line-height: 4.2rem;
    letter-spacing: 3.5px;
    text-transform: uppercase;
    font-weight: 500;
    color: #5b627d;
    margin-top: 50px;
    margin-bottom: 10px;
    padding-left: 15px;
}

/*-------------------------------- rugGallery-container --------------------------------*/
.rugGallery-container {
  padding: 0;
  margin: 0;
  background-color: #F1EFEF;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/*-------------------------------- card-container --------------------------------*/
/* .card-container {
  margin: auto;
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 50px;
} */
.card-container{
  height: auto;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/*-------------------------------- card --------------------------------*/
/* .card {
  position: relative; 
  width: 80%;
  max-height: 550px;
  height: 500px;
  
  margin: 50px;
  overflow: hidden;
} */

.card{
  height: 500px;
  width: 300px;
  margin: 30px;
  box-shadow: 5px 5px 15px #a7a38ca6;
  border-radius: 10px;
  background-color: #f1efef;
  overflow: hidden;
}
.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content {
  width: 100%;
  height: 65px;
  box-sizing: border-box;
  position: sticky;
  bottom: 0;
  color: #FFF;
  background-color: rgba(66, 64, 64, 0.616);
}

@media (max-width:530px){
  .gallery-title h1{
    margin-top: 10px;
    margin-bottom: 0;
  }
  .card{
    margin: 15px;
  }
  .card-container{
    width: 100%;
  }
}
</style>
