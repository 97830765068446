<template>
  <section class="header_component">
    
    <div class="header_container">
      <div class="container_img">
        <img src="../../assets/log.webp" name="logo" alt="img-logo" class="header_img" />
        <h1 class="header_text">SUDAMERICANA RUGS</h1>
      </div>
      
    
      
      <div class="container_nav">
        <nav>
          <ul class="navigation">
            <li class="navigation-item">
              <router-link to="/index">
                <a class="nav-link" href="#"><span class="a-span"  id="a-home"></span></a>
              </router-link>
            </li>
  
            <li class="navigation-item">
              <router-link to="/rugGallery">
                <a class="nav-link" href="#"><span class="a-span"  id="a-gallery"></span></a>
              </router-link>
            </li>
  
            <li class="navigation-item">
              <router-link to="/aboutUs">
                <a class="nav-link" href="#"><span class="a-span"  id="a-about"></span></a>
              </router-link>
            </li>
  
            <li class="navigation-item">
              <router-link to="/contactUs">
                <a class="nav-link" href="#"><span class="a-span"  id="a-contact"></span></a>
              </router-link>
            </li>
          </ul>
        </nav>

      </div>
    
    </div>
  
  </section>
</template>

<script>
export default {
  name: "headerComponent",
  data() {
    return {
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="css">
* {
  margin: 0;
}
/* .header_component {
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: grid;
} */
/*-------------------------------- header_container --------------------------------*/
/* .header_container { */
 
 
  /*  min-height: 95px;
  background-color: green;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
   */
 
 
   /*   min-height: 95px;
   display: grid;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    
}

@media screen and (max-width: 710px){
  .header_container{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    
  }
  .container_img{
    grid-column: 1/4;
    margin: auto;
  }
  }
  .container_nav {
    
} */


/*-------------------------------- container_img --------------------------------*/
/* .container_img {
  grid-column: 1/2; 
  display: flex;
  align-items: center;
  margin: auto;
  margin-right: 15px;
  
}
.header_img {
  max-height: 80px;
}
.header_text {
  text-align: center;
  margin: auto;
  margin-left: 10px;
} */
/*-------------------------------- container_nav --------------------------------*/
/* .container_nav {
  grid-column: 2/4;
}
.navigation {
  list-style-type: none;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navigation-item a {
  margin-top: 10px;
  padding: 12px;
  text-decoration: none;
  font-size: 15px;
  width: 150px;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
} */



.header_component {
  background-color: #fff;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.header_container {
  min-height: 95px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  align-items: center;
  
}

.container_img {
  display: flex; /* Permite que los elementos hijos se coloquen en una fila */
  align-items: center; /* Centra verticalmente los elementos hijos */
  justify-content: center;
}
.container_img{
  user-select: none;
}
.header_img {
  max-height: 80px;
}

.container_nav {
  text-align: center;
  
}

.navigation {
  list-style-type: none;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
}

.navigation-item a{
 text-decoration: none;
 padding: 0;
}
.a-span{
  margin-top: 10px;
  text-decoration: none;
  font-size: 15px;
  width: 150px;
  text-transform: uppercase;
  color: black;
  font-weight: bold; 
}

.nav-link {
  text-decoration: none;
  
}

#a-home:before{
  content:"Home";
}
#a-gallery:before{
  content:"Rug Gallery";
}
#a-about:before{
  content:"About Us";
}
#a-contact:before{
    content: "Contact Us";
  }

@media (max-width: 709px) {
  .header_container {
    grid-template-columns: 1fr; /* Cambia a una sola columna */
    text-align: center; /* Centra horizontalmente los elementos */
  }
  .navigation-item a{
    padding: 4px;
  }
  }

  @media (max-width: 600px){
#a-gallery:before{
  content:"Gallery";
}
#a-about:before{
  content:"About";
}
  #a-contact:before{
    content: "Contact";
  }
  }
  
</style>
