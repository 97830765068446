<template>
  <div id="app">
    <Header class="app_header" />
    <router-view class="app_content"></router-view>
    <Footer class="app_footer" />
  </div>
</template>

<script>
import Header from "./components/Common/Header.vue";
import Footer from "./components/Common/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
* {
  margin: 0;
  box-sizing: border-box;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
  background-color: #e9f6ff;
}

.app_content {
  flex: 1;
  overflow-y: auto;
}

.app_footer {
  width: 100%;
}

.app_header {
  min-height: 50px;
}
</style>
