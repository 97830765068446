var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"home-container"},[_c('div',{staticClass:"imagen-inicio"},[_c('div',{staticClass:"wave",staticStyle:{"height":"150px","overflow":"hidden"}},[_c('svg',{staticStyle:{"height":"100%","width":"100%"},attrs:{"viewBox":"0 0 500 150","preserveAspectRatio":"none"}},[_c('path',{staticStyle:{"stroke":"none","fill":"#fff"},attrs:{"d":"M-26.80,20.23 C66.87,172.22 344.52,-40.94 525.11,86.36 L500.00,150.00 L-39.21,223.53 Z"}})])])]),_vm._m(0),_c('div',{staticClass:"preview-container"},[_c('div',{staticClass:"card",attrs:{"id":"card1"}},[_c('div',{staticClass:"card-content"},[_vm._m(1),_c('div',{staticClass:"container-content"},[_vm._m(2),_c('div',{staticClass:"button-prev"},[_c('router-link',{attrs:{"to":"/rugGallery"}},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v("Rug Gallery")])])],1)])])]),_c('div',{staticClass:"card",attrs:{"id":"card2"}},[_c('div',{staticClass:"card-content"},[_vm._m(3),_c('div',{staticClass:"container-content"},[_vm._m(4),_c('div',{staticClass:"button-prev"},[_c('router-link',{attrs:{"to":"/aboutUs"}},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v("View More")])])],1)])])])]),_c('div',{staticClass:"home-end"},[_c('div',{staticClass:"prev-contact"},[_vm._m(5),_c('div',{staticClass:"content-contact"},[_vm._m(6),_c('div',{staticClass:"button-prev"},[_c('router-link',{attrs:{"to":"/contactUs"}},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v("CONTACT US")])])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-inicio"},[_c('h4',[_vm._v("Welcome to Sudamericana Rugs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-prev"},[_c('img',{attrs:{"src":require("../assets/img-content/8.jpg"),"alt":"img-preview"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h3',[_vm._v("White sheep rug")]),_c('h1',[_vm._v("BEST PRICE")]),_c('h2',[_vm._v("Multiple models")]),_c('p',{staticClass:"card-description"},[_vm._v("Discover the natural elegance with our sheepskin rugs. Explore our range of models to find the perfect rug that suits your style and space.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-prev"},[_c('img',{attrs:{"src":require("../assets/img-content/3.jpg"),"alt":"img-about"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h3',[_vm._v("Know more")]),_c('h1',[_vm._v("ABOUT US")]),_c('h2',[_vm._v("Sudamericana Rugs")]),_c('p',{staticClass:"card-description"},[_vm._v("Explore our history, values, and learn about our steadfast commitment to quality."),_c('br'),_vm._v(" Discover more about us and what makes us unique. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-contact"},[_c('img',{attrs:{"src":require("../assets/img-content/9.jpg"),"alt":"img-contac"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-info"},[_c('h1',[_vm._v("ORDER NOW!")]),_c('h2',[_vm._v("Contact Us! Feel free to reach out to us through our contact section to place your order. Each order can be customized in size, shape, and color according to your preferences. We look forward to hearing from you.")])])
}]

export { render, staticRenderFns }