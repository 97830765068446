<template>
  <!-------------------------------- home-container -------------------------------->
  <section class="home-container">
    <div class="imagen-inicio">
      <div class="wave" style="height: 150px; overflow: hidden">
        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%">
          <path d="M-26.80,20.23 C66.87,172.22 344.52,-40.94 525.11,86.36 L500.00,150.00 L-39.21,223.53 Z"
            style="stroke: none; fill: #fff"></path>
        </svg>
      </div>
    </div>

    <!-------------------------------- sub-inicio -------------------------------->
    <div class="sub-inicio">
      <h4>Welcome to Sudamericana Rugs</h4>
    </div>

    <!-------------------------------- preview-container -------------------------------->
    <div class="preview-container">

      <div id="card1" class="card">
        <div class="card-content">
          <div class="img-prev">
            <img src="../assets/img-content/8.jpg" alt="img-preview" />
          </div>

          <div class="container-content">
            <div class="content">
              <h3>White sheep rug</h3>
              <h1>BEST PRICE</h1>
              <h2>Multiple models</h2>
              <p class="card-description">Discover the natural elegance with our sheepskin rugs. Explore our range of
                models to find the perfect rug that suits your style and space.</p>
            </div>
            <div class="button-prev">
              <router-link to="/rugGallery">
                <a class="nav-link" href="#">Rug Gallery</a>
              </router-link>
            </div>

          </div>

        </div>


      </div>

      <div id="card2" class="card">
        <div class="card-content">
          <div class="img-prev">
            <img src="../assets/img-content/3.jpg" alt="img-about">
          </div>
          <div class="container-content">
            <div class="content">
              <h3>Know more</h3>
              <h1>ABOUT US</h1>
              <h2>Sudamericana Rugs</h2>
              <p class="card-description">Explore our history, values, and learn about our steadfast commitment to quality.<br> Discover more about us and what makes us unique.
              </p>
            </div>
            <div class="button-prev">
              <router-link to="/aboutUs">
                <a class="nav-link" href="#">View More</a>
              </router-link>

            </div>

          </div>
        </div>
      </div>

    </div>



    <div class="home-end">
      <div class="prev-contact">

        <div class="img-contact">
          <img src="../assets/img-content/9.jpg" alt="img-contac">
        </div>

        <div class="content-contact">

          <div class="contact-info">
            <h1>ORDER NOW!</h1>
            <h2>Contact Us! Feel free to reach out to us through our contact section to place your order. Each order can be customized in size, shape, and color according to your preferences. We look forward to hearing from you.</h2>
          </div>
          <div class="button-prev">
            <router-link to="/contactUs">
              <a class="nav-link" href="#">CONTACT US</a>
            </router-link>
          </div>
        </div>

      </div>


    </div>







  </section>
</template>








<script>
export default {
  name: "indexComponent",
  data() {
    return {};
  },
  mounted() { },
  methods: {},
};
</script>




<style scoped lang="css">
/*-------------------------------- home-container -------------------------------->*/
.home-container {
  padding: 0;
  margin: 0;
  background-color: #fff;
}

h1 {
  color: #232938;
}

/*-------------------------------- imagen-inicio -------------------------------->*/
.imagen-inicio {
  padding: 0;
  margin: 0;
  background: url(../assets/img-content/5.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 800px;
  overflow: hidden;
}

.wave {
  position: relative;
  top: 82%;
}

/*-------------------------------- sub-inicio -------------------------------->*/

.sub-inicio {
  height: 150px;
  padding: 0;
  margin-top: 70px;
  margin-bottom: 40px;
}

.sub-inicio h4 {
  color: #232938;
  font-size: 55px;
  font-weight: 650;
}

/*-------------------------------- preview-container -------------------------------->*/

.preview-container {
  display: flex;
  padding: 10px;
  justify-content: center;
  position: relative;
}

.preview-container::before,
.preview-container::after {
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0));
    background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0)); */
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  width: 100%;
  margin: -15px;
}

.preview-container::before {
  top: 0;
}

.preview-container::after {
  bottom: 0;

}

#card1 {
  margin-left: 50px;
  margin-right: 25px;
  background-color: #F1EFEF;
}

#card2 {
  margin-right: 50px;
  margin-left: 25px;
  background-color: #F1EFEF;
}

.card {
  display: flex;
  height: 400px;
  width: 750px;
  padding: 20px;
  box-shadow: 5px 5px 15px #a7a38ca6;
  border-radius: 10px;
}

.card img {
  height: 300px;
  width: 300px;
  border-radius: 10px;

}


.button-prev {
  margin: 10px;
}

a {
  text-decoration: none;
}

.button-prev .nav-link {
  text-decoration: none;
  text-transform: uppercase;
  background-color: #a7a38c;
  padding: 10px;
  border: 0;
  margin: -1px;
  border-radius: 2px;
  letter-spacing: 1px;
  color: white;
  cursor: pointer;
}

.button-prev .nav-link:hover {
  background: #928d6f;
  transition: all .4s ease-in-out;
}

.card-content {
  display: flex;
  flex-direction: row;
}

.card .card-content p {
  font-size: 16px;
  margin: 1px;
  margin-top: 10px;
}

.card .card-content h3 {
  font-size: 14px;
  line-height: 1rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-weight: 500;
  color: #5b627d;
}

.card .card-content h1 {
  font-size: 28px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.card .card-content h2 {
  font-size: 16px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-weight: 600;
}

.img-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.container-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.card-description {
  text-transform: none;
  letter-spacing: 0;
  color: black;
  font-size: .7em;
  line-height: 1.6em;
  margin-right: 25px;
  text-align: start;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  overflow: hidden;
}




/*-------------------------------- home-end -------------------------------->*/
.home-end {
  display: flex;
  height: 600px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

}

.prev-contact {
  display: flex;
  flex-direction: row;
  height: 470px;
  width: 50%;
  background-color: #F1EFEF;
  box-shadow: 5px 5px 15px #a7a38ca6;
  border-radius: 10px;
  padding: 20px;

}

.img-contact {
  display: flex;
  align-items: center;
}

.img-contact img {
  display: flex;
  height: 90%;
  width: 90%;
  border-radius: 20px;
  flex: 1 1 0%;
}

.content-contact {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
}

.contact-info {
  display: flex;
  flex-direction: column;
  height: 90%;
  padding: 10px;
  position: relative;

}

.contact-info h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 42px;
  height: 30%;
  position: relative;
}

.contact-info h2::before {
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0));
    background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0)); */
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30%;
  /* Alinea el pseudo-elemento en la parte inferior del contenedor */
  height: 1px;
  width: 90%;
}

.contact-info h2::after {
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0));
    background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0)); */
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 8%;
  /* Alinea el pseudo-elemento en la parte inferior del contenedor */
  height: 1px;
  width: 90%;
}

.contact-info h2 {
  width: 100%;
  padding: 3% 6% 0% 6%;
  text-transform: none;
  letter-spacing: 0;
  color: black;
  font-size: 1.25em;
  line-height: 1.6em;
  text-align: start;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 7;
  line-clamp: 7;
  overflow: hidden;
}


@media screen and (max-width: 1275px) {
  .preview-container {
    flex-direction: column;
    padding: 0;
  }

  #card1,
  #card2 {
    margin: 10px;
    padding: 10px;
    height: auto;
    width: 95%;
    align-self: center;
  }

  /* .card img{
        max-width: 300px;
        padding: 0;
        margin: 0;
      } 
      /* .card-content{
        flex-direction: column;
    } */

  .sub-inicio h4 {
    font-size: calc(2.2em + 1vw);
  }

  .home-end {
    height: auto;
    margin-bottom: 15px;
  }

  .prev-contact {

    width: 95%;
  }
}

@media screen and (max-width: 900px) {


  .card-content {
    flex-direction: column;
  }

  .home-end {
    height: auto;
  }

  .prev-contact {
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    height: auto;
    width: 95%;
    align-self: center;
    align-items: center;
  }

  .img-contact img {
    max-width: 300px;
  }

  .contact-info h1 {
    font-size: 2.8rem;
    line-height: 3.9rem;
    font-weight: 500;
  }

  .contact-info h2 {
    width: 100%;
    font-size: 16px;
    margin: 1px;
  }

  .contact-info h2::before {
    top: 30%;
  }

  .contact-info h2::after {
    bottom: 2%;
  }

}
</style>
